import "../../App.css";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


import { useEffect, useState } from "react";
import { getAuthUser } from "../../service/mftc-api";


import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import NotFound from "./pages/notfound/NotFound";
import Component79Page from "./pages/Component79Page/Component79Page";
import Component80Page from "./pages/Component80Page/Component80Page";
import Component81Page from "./pages/Component81Page/Component81Page";
import Component82Page from "./pages/Component82Page/Component82Page";


function AppClient() {



  const [authUser, setAuthUser] = useState(getAuthUser());









  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;

if (window.navigator.standalone || isStandalone) {
                    return  !authUser ? <Component82Page/>    : <Component82Page/>
                          } else {
                    return  !authUser ? <Component82Page/>    : <Component82Page/>
                          }
  };


  return (

      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {
            }<Route path="/about" element={<Component79Page/>}/>
<Route path="/contact" element={<Component80Page/>}/>
<Route path="/legal" element={<Component81Page/>}/>
<Route path="/home" element={<Component82Page/>}/>
{
          }
          
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

  );
}

export default AppClient;



